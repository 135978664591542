<script lang="ts" setup>
import CloseOutlined from './icons/figma/CloseOutlined.vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  closeOnOverlayClick: {
    type: Boolean,
    default: true
  },
  heightOverride: {
    type: String,
    default: ''
  },
  widthOverride: {
    type: String,
    default: ''
  },
  borderRadius: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
function handleOverlayClick() {
  if (props.closeOnOverlayClick) {
    handleClose()
  }
}

watch(
  () => props.show,
  (val) => {
    if (val) {
      document.body.style.overflow = 'hidden'
      emit('update:show', true)
    } else {
      document.body.style.overflow = 'auto'
    }
  }
)

onUnmounted(() => {
  document.body.style.overflow = 'auto'
})
</script>
<template>
  <Teleport to="body" :disabled="disabled">
    <div
      class="fixed left-0 top-0 z-50 h-[100vh] w-[100vw] overflow-hidden overscroll-none bg-gray-70 bg-opacity-90 transition-opacity duration-150 ease-in"
      :class="{
        'invisible opacity-0': !props.show,
        visible: props.show
      }"
      @click="handleOverlayClick"
      @keyup.escape="handleClose"
    >
      <div
        v-if="props.show"
        class="absolute left-[50%] top-0 md:top-[50%] h-auto w-[95vw] -translate-x-1/2 md:-translate-y-1/2 overscroll-none rounded-card bg-white md:w-[80vw]"
        :style="{
          height: props.heightOverride ? props.heightOverride : undefined,
          width: props.widthOverride ? props.widthOverride : undefined,
          borderRadius: props.borderRadius ? props.borderRadius : undefined
        }"
        @click.stop
      >
        <div
          class="flex w-full flex-row justify-between px-[0.88rem] py-[1.88rem] text-right md:px-[1.88rem]"
        >
          <div class="text-h4 font-medium">{{ props.title }}</div>
          <button type="button" aria-label="close" @click="handleClose">
            <CloseOutlined class="h-[1.5rem] w-[1.5rem] fill-gray-60" />
          </button>
        </div>
        <slot></slot>
        <div class="w-full px-4 py-4 text-right"></div>
      </div>
    </div>
  </Teleport>
</template>
