<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core'
import type { PropType } from 'vue'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import type { TLPhoneInputValue, TLPhoneInput } from '../../../types/formkit-custom'

const props = defineProps({
  context: {
    type: Object as PropType<FormKitFrameworkContext<TLPhoneInputValue> & TLPhoneInput>,
    default: null
  }
})

// const firstEvent = ref(true)
const isValid = ref(true)

function handleInput(value?: string) {
  // if (firstEvent.value) {
  //   firstEvent.value = false
  //   return
  // }
  props.context.node.input({
    value,
    isValid: isValid.value || false
  })
}

function handleEnterKeyUp() {
  if (props.context.enterKeyUpHandler) {
    props.context.enterKeyUpHandler()
  }
}
</script>

<script lang="ts">
export default {
  components: {
    // @ts-ignore
    VueTelInput
  }
}
</script>

<template>
  <vue-tel-input
    style-classes="dark:bg-gray-60 !rounded-input"
    :input-options="{
      styleClasses: 'px-3 py-[0.75rem] !rounded-input dark:bg-gray-60 bg-white dark:text-white'
    }"
    :default-country="'HK'"
    mode="international"
    :model-value="props.context._value?.value"
    @on-input="handleInput"
    @validate="isValid = $event.valid"
    @keyup.enter="handleEnterKeyUp"
  ></vue-tel-input>
</template>

<style lang="scss">
.vue-tel-input {
  border: 0px;
}

.vti__dropdown {
  @apply rounded-input;
}
.vti__dropdown:hover {
  @apply rounded-input bg-primary;
}

.vti__dropdown-item {
  @apply font-light;
  strong {
    @apply font-light;
  }
  @apply bg-white text-primary;
  &.highlighted {
    @apply bg-primary text-white;
  }
}

.vti__input {
  @apply py-[0.75rem] pr-[1.25rem];
}

.dark {
  .vti__dropdown.open {
    @apply bg-gray-60;
  }
  .vti__dropdown {
    @apply rounded-input;
  }
  .vti__dropdown:hover {
    @apply rounded-input bg-white;
  }
  .vti__dropdown-item {
    @apply bg-gray-60 text-gray-30;
    &.highlighted {
      @apply bg-white text-primary;
    }
  }
}
</style>
