export function isSuperAdmin(user: ReturnType<typeof useAuth>['data']['value']) {
  if (!user) return false
  return (user.user_roles || []).includes('JOB_ADMIN')
}

export function isHrAdmin(user: ReturnType<typeof useAuth>['data']['value']) {
  if (!user) return false
  return (user.user_roles || []).includes('COMPANY_STAFF')
}

export function getPrimaryUserRole(
  user: ReturnType<typeof useAuth>['data']['value']
): 'admin' | 'employer' | 'candidate' {
  if (isSuperAdmin(user)) {
    return 'admin'
  }
  if (isHrAdmin(user)) {
    return 'employer'
  }
  return 'candidate' // default 'STUDENT'
}

export function isPrimaryCandidate(user: ReturnType<typeof useAuth>['data']['value']) {
  return getPrimaryUserRole(user) === 'candidate'
}
