import { LRUCache } from 'lru-cache'

export default defineNuxtPlugin(() => {
  // These cache is shared on the same frontend session
  const strapiGetCache = new LRUCache<string, any>({
    max: 5000
  })
  return {
    provide: {
      strapiGetCache
    }
  }
})
