<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      d="M16.534 29.36C16.166 29.36 15.918 29.396 15.79 29.432V31.788C15.942 31.824 16.132 31.834 16.394 31.834C17.352 31.834 17.942 31.35 17.942 30.532C17.942 29.8 17.434 29.36 16.534 29.36ZM23.508 29.384C23.108 29.384 22.848 29.42 22.694 29.456V34.676C22.848 34.712 23.096 34.712 23.32 34.712C24.954 34.724 26.018 33.824 26.018 31.92C26.03 30.26 25.06 29.384 23.508 29.384Z"
      fill="#D53216"
    />
    <path
      d="M28 4H12C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8V40C8 41.0609 8.42143 42.0783 9.17157 42.8284C9.92172 43.5786 10.9391 44 12 44H36C37.0609 44 38.0783 43.5786 38.8284 42.8284C39.5786 42.0783 40 41.0609 40 40V16L28 4ZM18.996 32.38C18.378 32.96 17.466 33.22 16.404 33.22C16.1981 33.2222 15.9923 33.2102 15.788 33.184V36.036H14V28.164C14.8069 28.0436 15.6222 27.9888 16.438 28C17.552 28 18.344 28.212 18.878 28.638C19.386 29.042 19.73 29.704 19.73 30.484C19.728 31.268 19.468 31.93 18.996 32.38ZM26.61 35.09C25.77 35.788 24.492 36.12 22.93 36.12C21.994 36.12 21.332 36.06 20.882 36V28.166C21.6892 28.0482 22.5043 27.9927 23.32 28C24.834 28 25.818 28.272 26.586 28.852C27.416 29.468 27.936 30.45 27.936 31.86C27.936 33.386 27.378 34.44 26.61 35.09ZM34 29.54H30.936V31.362H33.8V32.83H30.936V36.038H29.124V28.06H34V29.54ZM28 18H26V8L36 18H28Z"
      fill="#D53216"
    />
  </svg>
</template>
