<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core'
import { useDropZone } from '@vueuse/core'
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import Modal from '../Modal.vue'
import DeleteOutlined from '../icons/figma/DeleteOutlined.vue'
import BlackButton from '../button/BlackButton.vue'
import type { TLImageInput, TLImageInputValue } from '../../../types/formkit-custom'
import FileInputIcon from './FileInputLogo.vue'

type TContext = FormKitFrameworkContext<TLImageInputValue> & TLImageInput

const props = defineProps({
  context: {
    type: Object as PropType<TContext>,
    default: null
  }
})

const fileInputRef = ref<HTMLInputElement | null>(null)
const dropZoneRef = ref<HTMLDivElement>()
const base64Img = ref<string>()
const cropperModalShow = ref(false)

function resetCropper() {
  if (cropper) {
    cropper.clear()
    cropper.reset()
  }
}

async function getDimensionFromBase64(base64: string) {
  const img = new Image()
  img.src = base64
  await img.decode()
  return {
    width: img.width,
    height: img.height
  }
}

function commitFiles(files: File[] | FileList | null) {
  // Reject empty files
  if (!files) {
    return
  }
  resetCropper()

  // Open the cropper modal
  if (files.length === 1 && files[0].type.startsWith('image/')) {
    const reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = () => {
      base64Img.value = reader.result as string
      cropperModalShow.value = true
    }
  }
}

function cancelCropperResult() {
  cropperModalShow.value = false
  resetCropper()
}

async function handleCropperResult() {
  if (!cropper) return
  // get the cropped image
  const base64 = await cropper.getDataURL()
  const file = await cropper.getFile()

  const { width, height } = await getDimensionFromBase64(base64)
  const fileWrapper = {
    width,
    height,
    file
  } as TLImageInputValue

  // close the cropper modal
  cropperModalShow.value = false
  resetCropper()
  // Update the model value
  props.context.node.input(fileWrapper)
}

function handleFileInput(event: Event) {
  const target = event.target as HTMLInputElement
  commitFiles(target.files)
}

function dropHandler(files: File[] | null) {
  commitFiles(files)
}

function reset() {
  resetCropper()
  props.context.node.input(null)
}

watch(
  () => props.context.value,
  (value: TLImageInputValue) => {
    if (value) {
      resetCropper()
      const reader = new FileReader()
      reader.readAsDataURL(value.file as File)
      reader.onload = () => {
        base64Img.value = reader.result as string
      }
    }
  }
)
const { isOverDropZone } = useDropZone(dropZoneRef, dropHandler)

onUnmounted(() => {
  resetCropper()
  if (cropper) {
    cropper.destroy()
  }
})
</script>

<template>
  <Modal
    :show="cropperModalShow"
    :close-on-overlay-click="false"
    :width-override="props.context.modalWidth"
    @update:show="
      (v) => {
        cropperModalShow = v
        if (!v) {
          resetCropper()
        }
      }
    "
  >
    <div class="flex flex-col gap-[1.88rem]">
      <div class="m-auto w-[90%]">
        <VuePictureCropper
          :box-style="{
            width: '100%',
            height: '400px',
            backgroundColor: 'transparent',
            margin: 'auto'
          }"
          :img="base64Img"
          :options="props.context.cropperOptions.options"
          :preset-mode="props.context.cropperOptions.presetMode"
        />
      </div>
      <div class="m-auto flex w-[90%] flex-row items-center justify-end gap-[0.62rem]">
        <BlackButton text="Cancel" variant="outlined" @click="cancelCropperResult"></BlackButton>
        <BlackButton text="Confirm" @click="handleCropperResult"></BlackButton>
      </div>
    </div>
  </Modal>
  <div
    class="file-input flex transform items-center justify-center bg-white transition-[height]"
    :class="{
      'h-[16.875rem]': !props.context.value,
      'h-auto': props.context.value
    }"
  >
    <div
      v-if="props.context.value"
      class="group flex w-full flex-col gap-[0.62rem] p-[1.25rem] text-center"
    >
      <div class="flex flex-row items-center gap-[1.25rem]">
        <div class="flex flex-1 flex-col truncate text-left">
          <img
            :src="base64Img"
            :class="props.context.previewOptions.class"
            :style="{
              width: props.context.previewOptions.width
                ? props.context.previewOptions.width
                : undefined,
              height: props.context.previewOptions.height
                ? props.context.previewOptions.height
                : undefined,
              maxWidth: props.context.previewOptions.maxWidth
                ? props.context.previewOptions.maxWidth
                : undefined
            }"
          />
        </div>
        <button
          type="button"
          aria-label="reset"
          class="flex-0 h-[1.5rem] w-[1.5rem]"
          @click="reset"
        >
          <DeleteOutlined />
        </button>
      </div>
    </div>
    <div
      v-else
      ref="dropZoneRef"
      class="group flex w-full cursor-pointer flex-col gap-[0.62rem] py-[3.12rem] text-center"
      @click="fileInputRef?.click()"
    >
      <div
        class="m-auto transform transition group-hover:scale-110"
        :class="{
          'animate-bounce': isOverDropZone
        }"
      >
        <FileInputIcon />
      </div>
      <a type="button" class="text-t5 font-light text-blue-40 group-hover:underline">
        {{ props.context.uploadText }}
        <input
          ref="fileInputRef"
          type="file"
          class="hidden"
          :accept="props.context.accept"
          @change="handleFileInput"
        />
      </a>
      <div class="text-t6 font-light text-gray-50">
        {{ $t('common.form.supportFileType') }}: {{ props.context.accept }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.formkit-inner:has(.file-input) {
  @apply overflow-hidden rounded-file ring-1 ring-gray-50;
}

[data-invalid] .formkit-inner:has(.file-input) {
  @apply ring-2 ring-red-40;
}
</style>
