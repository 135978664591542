<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core'
import { useDropZone } from '@vueuse/core'
import type { ConcreteComponent } from 'vue'
import PDFIcon from '../icons/others/files/Pdf.vue'
import DeleteOutlined from '../icons/figma/DeleteOutlined.vue'
import Loading from '../icons/others/Loading.vue'
import type { TLFileInput, TLFileInputValue } from '../../../types/formkit-custom'
import FileInputIcon from './FileInputLogo.vue'

const iconMap: Record<string, ConcreteComponent> = {
  'application/pdf': PDFIcon as ConcreteComponent
}

type TContext = FormKitFrameworkContext<TLFileInputValue> & TLFileInput

const props = defineProps({
  context: {
    type: Object as PropType<TContext>,
    default: null
  }
})

const ext2FileType: Record<string, string> = {
  '.pdf': 'application/pdf'
}

const fileInputRef = ref<HTMLInputElement | null>(null)
const dropZoneRef = ref<HTMLDivElement>()

function validateFileTypes(files: File[] | FileList | null) {
  // Reject wrong file types
  if (props.context.accept) {
    const acceptTypes = props.context.accept.split(',').map((type) => ext2FileType[type])

    for (const file of files || []) {
      if (!acceptTypes.includes(file.type)) {
        alert(`File type not accepted: ${file.type}`)
        return false
      }
    }
  }
  return true
}

function commitFiles(files: File[] | FileList | null) {
  // Reject empty files
  if (!files) return false
  if (props.context.multiple) {
    props.context.node.input(files)
  } else {
    props.context.node.input(files[0])
  }
}

function handleFileInput(event: Event) {
  const target = event.target as HTMLInputElement
  if (validateFileTypes(target.files)) {
    commitFiles(target.files)
  }
}

function dropHandler(files: File[] | null) {
  if (validateFileTypes(files)) {
    commitFiles(files)
  }
}

function reset() {
  if (props.context.multiple) {
    props.context.node.input([])
  } else {
    props.context.node.input(null)
  }
}

const { isOverDropZone } = useDropZone(dropZoneRef, dropHandler)
</script>

<template>
  <div
    class="file-input flex transform items-center justify-center bg-white transition-[height]"
    :class="{
      'h-[16.875rem]': !props.context.value,
      'h-[5.5rem]': props.context.value
    }"
  >
    <div
      v-if="props.context.value"
      class="group flex w-full flex-col gap-[0.62rem] p-[1.25rem] text-center"
    >
      <div class="flex flex-row items-center gap-[1.25rem]">
        <div v-if="!props.context.multiple && props.context.value" class="h-[3rem] w-[3rem]">
          <component :is="iconMap[(props.context.value as File).type]" />
        </div>
        <div class="flex flex-1 flex-col truncate text-left">
          <div class="text-t5 font-light">{{ (props.context.value as File).name }}</div>
          <div>{{ ((props.context.value as File).size / (1024 * 1024)).toFixed(2) }} MB</div>
        </div>
        <button
          v-if="props.context.disabled"
          type="button"
          aria-label="loading"
          class="flex-0 h-[1.5rem] w-[1.5rem]"
          disabled
        >
          <Loading />
        </button>
        <button
          type="button"
          aria-label="reset"
          class="flex-0 h-[1.5rem] w-[1.5rem]"
          :disabled="props.context.disabled"
          @click="reset"
        >
          <DeleteOutlined />
        </button>
      </div>
    </div>
    <div
      v-else
      ref="dropZoneRef"
      class="group flex w-full cursor-pointer flex-col gap-[0.62rem] py-[3.12rem] text-center"
      @click="fileInputRef?.click()"
    >
      <div
        class="m-auto transform transition group-hover:scale-110"
        :class="{
          'animate-bounce': isOverDropZone
        }"
      >
        <FileInputIcon />
      </div>
      <a type="button" class="text-t5 font-light text-blue-40 group-hover:underline">
        {{ props.context.uploadText }}
        <input
          ref="fileInputRef"
          type="file"
          class="hidden"
          :accept="props.context.accept"
          @change="handleFileInput"
        />
      </a>
      <div class="text-t6 font-light text-gray-50">
        {{ $t('common.form.supportFileType') }}: {{ props.context.accept }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.formkit-inner:has(.file-input) {
  @apply overflow-hidden rounded-file ring-1 ring-gray-50;
}

[data-invalid] .formkit-inner:has(.file-input) {
  @apply ring-2 ring-red-40;
}
</style>
