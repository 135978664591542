import validate from "/mono-repo/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/mono-repo/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  hradmin: () => import("/mono-repo/frontend/middleware/hradmin.ts"),
  localeauth: () => import("/mono-repo/frontend/middleware/localeauth.ts"),
  superadmin: () => import("/mono-repo/frontend/middleware/superadmin.ts"),
  tlskip: () => import("/mono-repo/frontend/middleware/tlskip.ts"),
  auth: () => import("/mono-repo/frontend/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}