import revive_payload_client_4sVQNw7RlN from "/mono-repo/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/mono-repo/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/mono-repo/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/mono-repo/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/mono-repo/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/mono-repo/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/mono-repo/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/mono-repo/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/mono-repo/frontend/.nuxt/formkitPlugin.mjs";
import i18n_yfWm7jX06p from "/mono-repo/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_HQNyLQlVwt from "/mono-repo/frontend/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/mono-repo/frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/mono-repo/frontend/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_client_LcKgStRyi6 from "/mono-repo/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import nuxt_plugin_03YjkxYbK5 from "/mono-repo/frontend/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_client_W86eSGFqyZ from "/mono-repo/frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_AOuQ1DYzjk from "/mono-repo/frontend/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/mono-repo/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_client_2rh1pDAIty from "/mono-repo/frontend/plugins/01.sentry.client.ts";
import _04_lru_client_8OT9TAwnJ6 from "/mono-repo/frontend/plugins/04.lru.client.ts";
import _06_downloadPDFs_client_giOJIjK8vS from "/mono-repo/frontend/plugins/06.downloadPDFs.client.ts";
import _07_nuxtVideo_client_BZRvAnLNoJ from "/mono-repo/frontend/plugins/07.nuxtVideo.client.ts";
import _08_ga_client_KfnZfzTQtU from "/mono-repo/frontend/plugins/08.ga.client.ts";
import _09_clarity_client_wUdbyolrJ1 from "/mono-repo/frontend/plugins/09.clarity.client.ts";
import _10_aos_client_GibvpGst1m from "/mono-repo/frontend/plugins/10.aos.client.ts";
import _11_fbq_client_9Vna6fa818 from "/mono-repo/frontend/plugins/11.fbq.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  i18n_yfWm7jX06p,
  plugin_HQNyLQlVwt,
  plugin_t2GMTTFnMT,
  plugin_iGLjBbor9q,
  plugin_client_LcKgStRyi6,
  nuxt_plugin_03YjkxYbK5,
  plugin_client_W86eSGFqyZ,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  _01_sentry_client_2rh1pDAIty,
  _04_lru_client_8OT9TAwnJ6,
  _06_downloadPDFs_client_giOJIjK8vS,
  _07_nuxtVideo_client_BZRvAnLNoJ,
  _08_ga_client_KfnZfzTQtU,
  _09_clarity_client_wUdbyolrJ1,
  _10_aos_client_GibvpGst1m,
  _11_fbq_client_9Vna6fa818
]