import { createInput } from '@formkit/vue'
import FormKitSelectV2 from '../src/components/form/FormKitSelectV2.vue'

export const TLSelectV2Input = createInput(FormKitSelectV2, {
  // the props here should sync with the type definition in formkit-custom.d.ts
  props: [
    "label",
    'multiple',
    'placeholder',
    'disabled',
    'searchable',
    'loading',
    'itemOptions',
    'dropDownWidthOverride',
    'openDirection'
  ]
})
