import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { isEdge } = useDevice()
  if (isEdge) {
    console.log('Disable sentry on edge')
    return
  }

  function shouldDrop(jsErrorMessage?: string, nuxtErrorMessage?: string) {
    if (
      jsErrorMessage?.includes('imported module') ||
      jsErrorMessage?.includes('chunk') ||
      nuxtErrorMessage?.includes('imported module') ||
      nuxtErrorMessage?.includes('chunk') ||
      jsErrorMessage?.includes('The request is not allowed by the user agent') ||
      nuxtErrorMessage?.includes('The request is not allowed by the user agent') ||
      jsErrorMessage?.includes('r["@context"].toLowerCase') ||
      nuxtErrorMessage?.includes('r["@context"].toLowerCase')
    ) {
      return true
    }
    return false
  }

  Sentry.init({
    enabled: config.public.sentryEnabled,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: false,
    dsn: config.public.sentryDSN,
    release: config.public.sentryRelease,
    environment: config.public.environmentName,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
        tracePropagationTargets: []
      }),
      new Sentry.Replay()
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    beforeSend(event, hint) {
      const error = hint.originalException ?? undefined
      let jsErrorMessage
      if (typeof error === 'string' || error === undefined) {
        jsErrorMessage = error
      } else if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        const castedError = error as { message: string }
        jsErrorMessage = castedError.message
      }

      const nuxtErrorMessage = useError().value?.message
      console.log('Sentry', jsErrorMessage, nuxtErrorMessage, error, hint)
      if (shouldDrop(jsErrorMessage, nuxtErrorMessage)) {
        return null
      }
      return event
    }
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
