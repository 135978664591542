<script setup lang="ts">
import { computed } from 'vue'
import iconProps, { tailwindMerge } from '../iconprops'
const props = defineProps(iconProps)
const defaultClasses = 'w-full h-full'
const mergedClasses = computed(() => tailwindMerge(defaultClasses, props.class))
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="mergedClasses"
    fill="currentColor"
  >
    <mask
      id="mask0_29_516"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      :class="mergedClasses"
    >
      <rect :class="mergedClasses" />
    </mask>
    <g mask="url(#mask0_29_516)">
      <path
        d="M12 14.9498C11.8667 14.9498 11.7417 14.9289 11.625 14.8873C11.5084 14.8456 11.4 14.7748 11.3 14.6748L6.67503 10.0498C6.4917 9.86643 6.4042 9.63726 6.41253 9.36226C6.42086 9.08726 6.5167 8.8581 6.70003 8.67476C6.88336 8.49143 7.1167 8.39976 7.40003 8.39976C7.68336 8.39976 7.9167 8.49143 8.10003 8.67476L12 12.5748L15.925 8.64976C16.1084 8.46643 16.3375 8.37893 16.6125 8.38726C16.8875 8.3956 17.1167 8.49143 17.3 8.67476C17.4834 8.8581 17.575 9.09143 17.575 9.37476C17.575 9.6581 17.4834 9.89143 17.3 10.0748L12.7 14.6748C12.6 14.7748 12.4917 14.8456 12.375 14.8873C12.2584 14.9289 12.1334 14.9498 12 14.9498Z"
      />
    </g>
  </svg>
</template>
