<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="100" viewBox="0 0 112 100" fill="none">
    <g clip-path="url(#clip0_1800_10575)">
      <path
        d="M57.3518 0.217994L53.7263 1.15292L9.05996 12.6715L5.4345 13.6064C3.67475 14.0623 2.1677 15.1977 1.24399 16.7634C0.320281 18.329 0.0553443 20.1972 0.507305 21.958L16.2281 82.9196C16.684 84.6793 17.8193 86.1864 19.385 87.1101C20.9507 88.0338 22.8188 88.2988 24.5796 87.8468L24.589 87.8444L76.4875 74.4608L76.4969 74.4584C78.2566 74.0025 79.7637 72.8671 80.6874 71.3014C81.6111 69.7358 81.876 67.8676 81.4241 66.1068L65.7033 5.14522C65.2474 3.38546 64.112 1.87841 62.5464 0.954696C60.9807 0.0309797 59.1125 -0.233963 57.3518 0.217994Z"
        fill="#F2F2F2" />
      <path
        d="M57.8447 2.12932L53.5506 3.23668L10.2215 14.4104L5.92743 15.5178C4.67427 15.8424 3.60106 16.6509 2.94326 17.7659C2.28546 18.8808 2.09679 20.2112 2.41864 21.4651L18.1394 82.4267C18.4641 83.6798 19.2726 84.7531 20.3875 85.4109C21.5025 86.0686 22.8329 86.2573 24.0867 85.9355L24.0961 85.933L75.9946 72.5495L76.0041 72.547C77.2572 72.2224 78.3304 71.4138 78.9882 70.2989C79.646 69.184 79.8346 67.8536 79.5128 66.5997L63.792 5.63811C63.4673 4.38496 62.6589 3.31176 61.5439 2.65396C60.429 1.99616 59.0986 1.80748 57.8447 2.12932Z"
        fill="white" />
      <path
        d="M54.6023 21.8702L28.3481 28.6407C28.0548 28.7163 27.7436 28.6723 27.4828 28.5185C27.2219 28.3646 27.0329 28.1134 26.9573 27.8202C26.8817 27.5269 26.9257 27.2157 27.0795 26.9549C27.2334 26.694 27.4846 26.505 27.7778 26.4294L54.032 19.659C54.3252 19.5837 54.6362 19.6278 54.8968 19.7817C55.1574 19.9356 55.3462 20.1867 55.4218 20.4797C55.4974 20.7728 55.4535 21.0839 55.2998 21.3446C55.1462 21.6053 54.8953 21.7944 54.6023 21.8702Z"
        fill="#F2F2F2" />
      <path
        d="M60.1253 24.4257L29.3104 32.3722C29.0171 32.4478 28.7059 32.4039 28.445 32.25C28.1842 32.0961 27.9952 31.8449 27.9196 31.5517C27.844 31.2585 27.8879 30.9472 28.0418 30.6864C28.1957 30.4256 28.4469 30.2366 28.7401 30.161L59.5551 22.2144C59.8483 22.1388 60.1596 22.1827 60.4204 22.3366C60.6812 22.4905 60.8702 22.7417 60.9458 23.0349C61.0214 23.3281 60.9775 23.6394 60.8236 23.9002C60.6697 24.161 60.4185 24.35 60.1253 24.4257Z"
        fill="#F2F2F2" />
      <path
        d="M58.9148 38.593L32.6606 45.3635C32.5154 45.4009 32.3642 45.4094 32.2158 45.3884C32.0673 45.3674 31.9244 45.3174 31.7953 45.2412C31.6661 45.165 31.5532 45.0642 31.4631 44.9443C31.3729 44.8245 31.3073 44.6881 31.2698 44.5429C31.2324 44.3977 31.2239 44.2466 31.2449 44.0981C31.2658 43.9497 31.3158 43.8068 31.392 43.6776C31.4682 43.5485 31.5691 43.4356 31.6889 43.3455C31.8087 43.2553 31.9451 43.1896 32.0903 43.1522L58.3445 36.3818C58.4897 36.3443 58.6409 36.3358 58.7893 36.3568C58.9378 36.3778 59.0807 36.4278 59.2098 36.504C59.339 36.5802 59.4519 36.681 59.542 36.8009C59.6322 36.9207 59.6978 37.0571 59.7353 37.2023C59.7727 37.3475 59.7812 37.4986 59.7602 37.6471C59.7393 37.7955 59.6893 37.9384 59.6131 38.0676C59.5369 38.1967 59.436 38.3096 59.3162 38.3998C59.1964 38.4899 59.06 38.5556 58.9148 38.593Z"
        fill="#F2F2F2" />
      <path
        d="M64.4378 41.1484L33.6229 49.095C33.4777 49.1324 33.3265 49.1409 33.178 49.12C33.0296 49.099 32.8867 49.049 32.7575 48.9728C32.6284 48.8966 32.5155 48.7957 32.4254 48.6759C32.3352 48.5561 32.2695 48.4197 32.2321 48.2745C32.1947 48.1293 32.1862 47.9782 32.2071 47.8297C32.2281 47.6812 32.2781 47.5383 32.3543 47.4092C32.4305 47.28 32.5314 47.1672 32.6512 47.077C32.771 46.9869 32.9074 46.9212 33.0526 46.8837L63.8676 38.9372C64.1608 38.8616 64.4721 38.9055 64.7329 39.0594C64.9937 39.2133 65.1827 39.4645 65.2583 39.7577C65.3339 40.0509 65.29 40.3622 65.1361 40.623C64.9822 40.8838 64.731 41.0728 64.4378 41.1484Z"
        fill="#F2F2F2" />
      <path
        d="M63.2272 55.3158L36.973 62.0862C36.6799 62.1616 36.3688 62.1175 36.1082 61.9636C35.8475 61.8097 35.6586 61.5586 35.5831 61.2655C35.5075 60.9724 35.5514 60.6613 35.7051 60.4006C35.8588 60.1398 36.1098 59.9508 36.4028 59.875L62.657 53.1045C62.9502 53.0289 63.2615 53.0729 63.5223 53.2268C63.7831 53.3806 63.9721 53.6318 64.0478 53.925C64.1234 54.2183 64.0794 54.5295 63.9255 54.7904C63.7717 55.0512 63.5205 55.2402 63.2272 55.3158Z"
        fill="#F2F2F2" />
      <path
        d="M68.7503 57.8711L37.9354 65.8177C37.7901 65.8553 37.6389 65.8639 37.4903 65.843C37.3417 65.8221 37.1988 65.7721 37.0695 65.696C36.9402 65.6198 36.8273 65.5189 36.737 65.399C36.6468 65.2792 36.5811 65.1427 36.5436 64.9974C36.5062 64.8521 36.4977 64.7009 36.5187 64.5523C36.5397 64.4038 36.5898 64.2608 36.6661 64.1317C36.7424 64.0025 36.8434 63.8896 36.9633 63.7995C37.0833 63.7093 37.2198 63.6437 37.3651 63.6064L68.1801 55.6598C68.4733 55.5842 68.7846 55.6282 69.0454 55.7821C69.3062 55.9359 69.4952 56.1871 69.5708 56.4803C69.6465 56.7736 69.6025 57.0848 69.4486 57.3457C69.2947 57.6065 69.0435 57.7955 68.7503 57.8711Z"
        fill="#F2F2F2" />
      <path
        d="M23.8661 35.5828L14.575 37.9788C14.4344 38.0149 14.2853 37.9937 14.1602 37.9199C14.0352 37.8462 13.9446 37.7258 13.9082 37.5853L11.7754 29.3149C11.7393 29.1743 11.7605 29.0252 11.8342 28.9001C11.908 28.7751 12.0283 28.6845 12.1688 28.6481L21.4599 26.2521C21.6005 26.216 21.7497 26.2371 21.8747 26.3109C21.9998 26.3847 22.0904 26.505 22.1268 26.6455L24.2596 34.9159C24.2957 35.0565 24.2745 35.2057 24.2008 35.3307C24.127 35.4557 24.0067 35.5464 23.8661 35.5828Z"
        fill="#E6E6E6" />
      <path
        d="M28.1786 52.3055L18.8875 54.7015C18.7469 54.7376 18.5978 54.7165 18.4727 54.6427C18.3477 54.569 18.2571 54.4486 18.2207 54.3081L16.0879 46.0377C16.0518 45.8971 16.073 45.7479 16.1467 45.6229C16.2205 45.4979 16.3408 45.4073 16.4813 45.3709L25.7724 42.9749C25.913 42.9388 26.0622 42.9599 26.1872 43.0337C26.3122 43.1074 26.4029 43.2278 26.4393 43.3683L28.5721 51.6387C28.6082 51.7793 28.587 51.9284 28.5133 52.0535C28.4395 52.1785 28.3192 52.2691 28.1786 52.3055Z"
        fill="#E6E6E6" />
      <path
        d="M32.491 69.0283L23.1999 71.4243C23.0593 71.4604 22.9101 71.4393 22.7851 71.3655C22.6601 71.2917 22.5694 71.1714 22.533 71.0309L20.4003 62.7605C20.3642 62.6199 20.3853 62.4707 20.4591 62.3457C20.5329 62.2207 20.6532 62.13 20.7937 62.0936L30.0848 59.6976C30.2254 59.6615 30.3746 59.6827 30.4996 59.7565C30.6246 59.8302 30.7153 59.9506 30.7517 60.0911L32.8845 68.3615C32.9205 68.5021 32.8994 68.6512 32.8256 68.7763C32.7519 68.9013 32.6315 68.9919 32.491 69.0283Z"
        fill="#E6E6E6" />
      <path
        d="M93.5615 15.671H39.9457C38.1278 15.6731 36.385 16.3961 35.0996 17.6815C33.8142 18.967 33.0912 20.7098 33.0891 22.5276V85.4837C33.0912 87.3015 33.8142 89.0443 35.0996 90.3297C36.385 91.6152 38.1278 92.3382 39.9457 92.3402H93.5615C95.3793 92.3382 97.1221 91.6152 98.4075 90.3297C99.693 89.0443 100.416 87.3015 100.418 85.4837V22.5276C100.416 20.7098 99.693 18.967 98.4075 17.6815C97.1221 16.3961 95.3793 15.6731 93.5615 15.671Z"
        fill="#E6E6E6" />
      <path
        d="M93.5616 17.6449H39.9457C38.6512 17.6463 37.4101 18.1612 36.4947 19.0766C35.5793 19.992 35.0644 21.2331 35.063 22.5276V85.4837C35.0644 86.7782 35.5793 88.0193 36.4947 88.9347C37.4101 89.85 38.6512 90.3649 39.9457 90.3664H93.5616C94.8561 90.3649 96.0972 89.85 97.0126 88.9346C97.9279 88.0193 98.4428 86.7782 98.4443 85.4837V22.5276C98.4428 21.2331 97.9279 19.992 97.0126 19.0766C96.0972 18.1613 94.8561 17.6464 93.5616 17.6449Z"
        fill="white" />
      <path
        d="M99.1943 100C106.107 100 111.711 94.3962 111.711 87.4836C111.711 80.571 106.107 74.9672 99.1943 74.9672C92.2817 74.9672 86.6779 80.571 86.6779 87.4836C86.6779 94.3962 92.2817 100 99.1943 100Z"
        fill="#2F80ED" />
      <path
        d="M105.18 85.851H100.827V81.4974C100.827 81.0645 100.655 80.6492 100.349 80.343C100.043 80.0369 99.6273 79.8649 99.1943 79.8649C98.7613 79.8649 98.346 80.0369 98.0399 80.343C97.7337 80.6492 97.5617 81.0645 97.5617 81.4974V85.851H93.2081C92.7752 85.851 92.3599 86.023 92.0537 86.3292C91.7476 86.6353 91.5756 87.0506 91.5756 87.4836C91.5756 87.9166 91.7476 88.3318 92.0537 88.638C92.3599 88.9441 92.7752 89.1161 93.2081 89.1161H97.5617V93.4697C97.5617 93.9027 97.7337 94.3179 98.0399 94.6241C98.346 94.9303 98.7613 95.1023 99.1943 95.1023C99.6273 95.1023 100.043 94.9303 100.349 94.6241C100.655 94.3179 100.827 93.9027 100.827 93.4697V89.1161H105.18C105.613 89.1161 106.029 88.9441 106.335 88.638C106.641 88.3318 106.813 87.9166 106.813 87.4836C106.813 87.0506 106.641 86.6353 106.335 86.3292C106.029 86.023 105.613 85.851 105.18 85.851Z"
        fill="white" />
      <path
        d="M85.4923 52.2391H58.3792C58.2291 52.2392 58.0805 52.2098 57.9418 52.1525C57.8032 52.0952 57.6771 52.0111 57.571 51.9051C57.4648 51.799 57.3806 51.6731 57.3231 51.5345C57.2657 51.3959 57.2361 51.2473 57.2361 51.0972C57.2361 50.9472 57.2657 50.7986 57.3231 50.66C57.3806 50.5214 57.4648 50.3955 57.571 50.2894C57.6771 50.1834 57.8032 50.0993 57.9418 50.042C58.0805 49.9847 58.2291 49.9553 58.3792 49.9554H85.4923C85.7949 49.9558 86.085 50.0762 86.2989 50.2903C86.5127 50.5044 86.6328 50.7946 86.6328 51.0972C86.6328 51.3999 86.5127 51.6901 86.2989 51.9042C86.085 52.1183 85.7949 52.2387 85.4923 52.2391Z"
        fill="#E6E6E6" />
      <path
        d="M90.2023 56.0927H58.3792C58.2291 56.0929 58.0805 56.0635 57.9418 56.0061C57.8032 55.9488 57.6771 55.8648 57.571 55.7587C57.4648 55.6527 57.3806 55.5267 57.3231 55.3881C57.2657 55.2495 57.2361 55.1009 57.2361 54.9509C57.2361 54.8008 57.2657 54.6523 57.3231 54.5136C57.3806 54.375 57.4648 54.2491 57.571 54.1431C57.6771 54.037 57.8032 53.9529 57.9418 53.8956C58.0805 53.8383 58.2291 53.8089 58.3792 53.8091H90.2023C90.5051 53.8091 90.7955 53.9294 91.0096 54.1435C91.2238 54.3576 91.3441 54.6481 91.3441 54.9509C91.3441 55.2537 91.2238 55.5441 91.0096 55.7583C90.7955 55.9724 90.5051 56.0927 90.2023 56.0927Z"
        fill="#E6E6E6" />
      <path
        d="M85.4923 69.5088H58.3792C58.2291 69.509 58.0805 69.4796 57.9418 69.4223C57.8032 69.365 57.6771 69.2809 57.571 69.1749C57.4648 69.0688 57.3806 68.9429 57.3231 68.8043C57.2657 68.6657 57.2361 68.5171 57.2361 68.367C57.2361 68.217 57.2657 68.0684 57.3231 67.9298C57.3806 67.7912 57.4648 67.6652 57.571 67.5592C57.6771 67.4532 57.8032 67.3691 57.9418 67.3118C58.0805 67.2545 58.2291 67.2251 58.3792 67.2252H85.4923C85.7951 67.2252 86.0856 67.3455 86.2997 67.5596C86.5138 67.7738 86.6341 68.0642 86.6341 68.367C86.6341 68.6699 86.5138 68.9603 86.2997 69.1744C86.0856 69.3885 85.7951 69.5088 85.4923 69.5088Z"
        fill="#E6E6E6" />
      <path
        d="M90.2023 73.3625H58.3792C58.2291 73.3626 58.0805 73.3332 57.9418 73.2759C57.8032 73.2186 57.6771 73.1345 57.571 73.0285C57.4648 72.9225 57.3806 72.7965 57.3231 72.6579C57.2657 72.5193 57.2361 72.3707 57.2361 72.2207C57.2361 72.0706 57.2657 71.922 57.3231 71.7834C57.3806 71.6448 57.4648 71.5189 57.571 71.4128C57.6771 71.3068 57.8032 71.2227 57.9418 71.1654C58.0805 71.1081 58.2291 71.0787 58.3792 71.0789H90.2023C90.3523 71.0787 90.5009 71.1081 90.6396 71.1654C90.7783 71.2227 90.9043 71.3068 91.0105 71.4128C91.1166 71.5189 91.2008 71.6448 91.2583 71.7834C91.3158 71.922 91.3453 72.0706 91.3453 72.2207C91.3453 72.3707 91.3158 72.5193 91.2583 72.6579C91.2008 72.7965 91.1166 72.9225 91.0105 73.0285C90.9043 73.1345 90.7783 73.2186 90.6396 73.2759C90.5009 73.3332 90.3523 73.3626 90.2023 73.3625Z"
        fill="#E6E6E6" />
      <path
        d="M52.3057 57.842H42.7106C42.5654 57.8418 42.4263 57.7841 42.3236 57.6815C42.221 57.5788 42.1632 57.4397 42.1631 57.2945V48.7536C42.1632 48.6084 42.221 48.4692 42.3236 48.3666C42.4263 48.2639 42.5654 48.2062 42.7106 48.2061H52.3057C52.4508 48.2062 52.59 48.2639 52.6926 48.3666C52.7953 48.4692 52.853 48.6084 52.8532 48.7536V57.2945C52.853 57.4397 52.7953 57.5788 52.6926 57.6815C52.59 57.7841 52.4508 57.8418 52.3057 57.842Z"
        fill="#E6E6E6" />
      <path
        d="M52.3057 75.1119H42.7106C42.5654 75.1117 42.4263 75.054 42.3236 74.9514C42.221 74.8487 42.1632 74.7096 42.1631 74.5644V66.0234C42.1632 65.8783 42.221 65.7391 42.3236 65.6365C42.4263 65.5338 42.5654 65.4761 42.7106 65.476H52.3057C52.4508 65.4761 52.59 65.5338 52.6926 65.6365C52.7953 65.7391 52.853 65.8783 52.8532 66.0234V74.5644C52.853 74.7096 52.7953 74.8487 52.6926 74.9514C52.59 75.054 52.4508 75.1117 52.3057 75.1119Z"
        fill="#E6E6E6" />
      <path
        d="M85.5225 33.0143H65.533C65.2302 33.0143 64.9398 32.894 64.7257 32.6799C64.5115 32.4658 64.3912 32.1753 64.3912 31.8725C64.3912 31.5697 64.5115 31.2793 64.7257 31.0651C64.9398 30.851 65.2302 30.7307 65.533 30.7307H85.5225C85.8253 30.7307 86.1157 30.851 86.3298 31.0651C86.544 31.2793 86.6643 31.5697 86.6643 31.8725C86.6643 32.1753 86.544 32.4658 86.3298 32.6799C86.1157 32.894 85.8253 33.0143 85.5225 33.0143Z"
        fill="#CCCCCC" />
      <path
        d="M90.2324 36.868H65.533C65.3831 36.868 65.2346 36.8384 65.0961 36.781C64.9576 36.7237 64.8317 36.6396 64.7257 36.5335C64.6196 36.4275 64.5355 36.3016 64.4782 36.1631C64.4208 36.0246 64.3912 35.8761 64.3912 35.7262C64.3912 35.5762 64.4208 35.4277 64.4782 35.2892C64.5355 35.1507 64.6196 35.0248 64.7257 34.9188C64.8317 34.8127 64.9576 34.7286 65.0961 34.6713C65.2346 34.6139 65.3831 34.5844 65.533 34.5844H90.2324C90.5352 34.5844 90.8257 34.7046 91.0398 34.9188C91.2539 35.1329 91.3742 35.4233 91.3742 35.7262C91.3742 36.029 91.2539 36.3194 91.0398 36.5335C90.8257 36.7477 90.5352 36.868 90.2324 36.868Z"
        fill="#CCCCCC" />
      <path
        d="M61.1091 41.5381H42.6806C42.5354 41.5379 42.3962 41.4802 42.2936 41.3775C42.191 41.2749 42.1332 41.1357 42.1331 40.9906V26.6082C42.1332 26.463 42.191 26.3238 42.2936 26.2212C42.3962 26.1186 42.5354 26.0608 42.6806 26.0607H61.1091C61.2542 26.0608 61.3934 26.1186 61.496 26.2212C61.5987 26.3238 61.6564 26.463 61.6566 26.6082V40.9906C61.6564 41.1357 61.5987 41.2749 61.496 41.3775C61.3934 41.4802 61.2542 41.5379 61.1091 41.5381Z"
        fill="#2F80ED" />
    </g>
    <defs>
      <clipPath id="clip0_1800_10575">
        <rect width="111.421" height="100" fill="white" transform="translate(0.289307)" />
      </clipPath>
    </defs>
  </svg>
</template>
