export default defineNuxtPlugin(() => {
  function setClarityUser(user: ReturnType<typeof useAuth>['data']['value']): void {
    console.log('clarity identify', user)

    if (!user) return

    const _window: { clarity: any } = window as any // Make typescript happy, because linktrk may not defined in window

    try {
      _window.clarity('identify', user.email)
    } catch (error) {
      // console.error('clarity identify error', error)
    }

    try {
      _window.clarity('set', 'primary_user_role', getPrimaryUserRole(user))
    } catch (error) {
      // console.error('clarity set primary_user_role error', error)
    }
  }

  const { data: user } = useAuth()
  setClarityUser(user.value)
  return {
    provide: {
      setClarityUser
    }
  }
})
