import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { PromisePool } from '@supercharge/promise-pool'
export default defineNuxtPlugin(() => {
  async function downloadPDFs(
    zipFileName: string,
    files: {
      appIdSlug: string
      firstName: string
      lastName: string
      email: string
      url: string
    }[]
  ) {
    const zip = new JSZip()

    // call this function to download all files as ZIP archive
    const { errors } = await PromisePool.withConcurrency(10)
      .for(files)
      .process(async (file) => {
        const res = await $fetch(file.url, { responseType: 'blob' })
        zip.file(`${file.firstName} ${file.lastName} [${file.email}].pdf`, res as Blob)
      })

    if (errors.length) {
      console.error('errors', errors)
    }
    // when all promises resolved - save zip file
    zip.generateAsync({ type: 'blob' }).then(function (blob) {
      saveAs(blob, zipFileName)
    })
  }
  return {
    provide: {
      downloadPDFs
    }
  }
})
