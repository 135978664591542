<script setup lang="ts">
const { finish: finishNuxtLoading } = useLoadingIndicator()
finishNuxtLoading()
if (process.client) {
  finishNuxtLoading()
}
</script>
<template>
  <NuxtLayout>
    <div>
      <NuxtLoadingIndicator color="#262626" />
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>
