export default defineNuxtPlugin(() => {
  const { gtag } = useGtag()
  const runtimeConfig = useRuntimeConfig()

  function setGAUser(user: ReturnType<typeof useAuth>['data']['value']): void {
    console.log('gtag identify', user)
    if (!user) return

    gtag('config', runtimeConfig.public.gtagID, {
      user_id: user.id_slug
    })
    gtag('set', 'user_properties', {
      first_name: user.first_name,
      last_name: user.last_name,
      primary_user_role: getPrimaryUserRole(user),
      email: user.email
    })
  }

  const { data: user } = useAuth()
  setGAUser(user.value)

  return {
    provide: {
      setGAUser
    }
  }
})
