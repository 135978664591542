import { en } from '@formkit/i18n'
import type { DefaultConfigOptions } from '@formkit/vue'

import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import { createProPlugin, toggle, taglist } from '@formkit/pro'
import { TLFileInput } from '../utils/TLFileInput'
import { TLImageInput } from '../utils/TLImageInput'
import { TLSelectV2Input } from '../utils/TLSelectV2Input'
import { TLPhoneInput } from '../utils/TLPhoneInput'

// @ts-ignore
import myTailwindTheme from './tailwind-formkit.theme'

const proPlugin = createProPlugin('fk-46cf44ec8d', {
  toggle,
  taglist
})

const config: DefaultConfigOptions = {
  locales: { en },
  locale: 'en',
  icons: {
    ...genesisIcons
  },
  // theme: 'genesis',
  config: {
    // pass our theme object to generateClasses
    classes: generateClasses(myTailwindTheme)
  },
  plugins: [proPlugin],
  inputs: {
    tlFile: TLFileInput,
    tlImage: TLImageInput,
    tlSelectV2: TLSelectV2Input,
    tlPhone: TLPhoneInput
  }
}

export default config
