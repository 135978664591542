<script lang="ts" setup>
/***
 * Imports
 */

import { onClickOutside, useResizeObserver } from '@vueuse/core'
import type { FormKitFrameworkContext } from '@formkit/core'
import ExpandMoreOutlined from '../icons/figma/ExpandMoreOutlined.vue'
import type {
  TLSelectV2Input,
  TLSelectInputValue,
  TLSelectOption,
  TLSelectComplexOption
} from '../../../types/formkit-custom'
import CheckBoxCheckedOutlined from '../icons/figma/CheckBoxCheckedOutlined.vue'
import CheckBoxBlankOutlined from '../icons/figma/CheckBoxBlankOutlined.vue'

let mdAndLarger = ref(false)
const nuxtApp = useNuxtApp()
const hasOwn = Object.hasOwn || ((obj, key) => Object.prototype.hasOwnProperty.call(obj, key))

// Viewport causes errors in design system
if (hasOwn(nuxtApp, '$viewport')) {
  // @ts-ignore
  mdAndLarger = computed(() => nuxtApp.$viewport.isGreaterOrEquals('md'))
}

/***
 * Types
 */
type TContext = FormKitFrameworkContext<TLSelectInputValue> & TLSelectV2Input

/***
 * Props
 */
const props = defineProps({
  context: {
    type: Object as PropType<TContext>,
    required: true
  }
})

/***
 * Data / Refs
 */
const menuOpened = ref(false)
const dropdownWrapperLeftEdge = ref(0)
const bodyWidth = ref(0)
const target = ref<HTMLElement>()
const dropdownWrapper = ref<HTMLElement>()
// const optionsWrapper = ref<HTMLElement>()
// const dropdown = ref<HTMLElement>()
const input = ref<{ $el: HTMLElement }>()
const dropDownTranslateX = ref(0)

/***
 * Methods
 */
function reset() {
  if (props.context.multiple) {
    props.context.node.input([])
    return
  }
  props.context.node.input(null)
}

function handleInput(option: TLSelectOption) {
  // Convert option to value
  const value = toOptionValue(option)
  // Find el in target that has data-headlessui-state being active
  if (props.context.loading) return
  if (props.context.multiple) {
    if (value === undefined) {
      return
    }
    // multi select
    const existingValue = props.context.value
    let newValue = []
    if (existingValue.includes(value)) {
      // pop
      newValue = existingValue.filter((v) => v !== value)
    } else {
      // append
      newValue = [...existingValue, value]
    }
    props.context.node.input(newValue)
  } else {
    // single select
    props.context.node.input(value)
  }
}

function filterFunction(
  options: string[] | TLSelectComplexOption[],
  searchTerm: string
): string[] | TLSelectComplexOption[] {
  const searches = searchTerm
    .split(',')
    .map((s) => s.trim())
    .filter((s) => s !== '')

  if (searches.length === 0) {
    return options
  }

  // The optionValues that matches the search term
  const filtered = options.filter((option: TLSelectOption) => {
    return searches.some((search) => {
      return toOptionName(option).toLowerCase().includes(search.toLowerCase())
    })
  })

  return filtered as string[] | TLSelectComplexOption[]
}

function handleOptionClicked(event: any) {
  handleInput(event.detail.value)
  menuOpened.value = props.context.multiple
}

function hasOptionSelected(option: TLSelectOption): boolean {
  if (Array.isArray(props.context.value)) {
    return props.context.value.some((v) => toOptionValue(v) === toOptionValue(option))
  }
  return props.context.value === toOptionValue(option)
}

function toOptionName(option: TLSelectOption): string {
  if (typeof option === 'string') {
    return option
  }
  return option.label
}

function toOptionValue(option: TLSelectOption): string {
  if (typeof option === 'string') {
    return option
  }
  return option.value
}

function valueToOption(value: typeof props.context.value): TLSelectOption | TLSelectOption[] {
  if (Array.isArray(value)) {
    return value.map((v) => {
      return props.context.itemOptions.find((o) => toOptionValue(o) === v) || v
    })
  }
  return props.context.itemOptions.find((o) => toOptionValue(o) === value) || value
}

/***
 * Computed Values
 */
const DEFAULT_DROPDOWN_WIDTH = 300
const dropdownWidth = computed(() => {
  if (!props.context.dropDownWidthOverride) {
    return DEFAULT_DROPDOWN_WIDTH
  }
  return parseInt(props.context.dropDownWidthOverride)
})

const hasSelection = computed(() => {
  const value = props.context.value
  if (Array.isArray(value)) {
    return value.length > 0
  }
  return value !== null && value !== undefined
})

const displayName = computed(() => {
  if (hasSelection.value) {
    return `${props.context.label || props.context.placeholder} (${selectedCount.value})`
  } else if (props.context.placeholder) {
    return props.context.placeholder
  } else {
    return 'Select an option'
  }
})

const selectedCount = computed(() => {
  const value = props.context.value
  if (Array.isArray(value)) {
    return value.length
  }
  return value !== null ? 1 : 0
})

function handleOpen() {
  if (props.context.disabled || props.context.loading) return
  menuOpened.value = true
  // focus to input
  autoFocusToInput()
}

let autoFocusTimeOut: ReturnType<typeof setTimeout>

function autoFocusToInput() {
  if (autoFocusTimeOut) clearTimeout(autoFocusTimeOut)
  autoFocusTimeOut = setTimeout(() => {
    if (!input.value?.$el) return
    if (input.value.$el instanceof HTMLInputElement) {
      input.value.$el.focus()
    }
  }, 200)
}

onUnmounted(() => {
  if (autoFocusTimeOut) clearTimeout(autoFocusTimeOut)
})
onMounted(() => {
  onClickOutside(target, () => {
    if (!menuOpened.value) return
    menuOpened.value = false
  })

  useResizeObserver(document.body, (entries) => {
    if (!menuOpened.value) return
    if (!dropdownWrapper.value) return
    dropdownWrapperLeftEdge.value = dropdownWrapper.value.getBoundingClientRect().left
    bodyWidth.value = entries[0].contentRect.width
    const transX = bodyWidth.value - dropdownWrapperLeftEdge.value - dropdownWidth.value
    if (transX < 0) {
      dropDownTranslateX.value = transX - 10
    } else {
      dropDownTranslateX.value = 0
    }
  })

  watch(menuOpened, (opened) => {
    if (target.value && opened && !mdAndLarger.value) {
      window.scroll({
        top: target.value.offsetTop - 40,
        behavior: 'smooth'
      })
    }
  })
})
</script>

<template>
  <div ref="target" class="select-input rounded-input" :multiple="props.context.mutiple">
    <ComboboxRoot
      class="relative rounded-input"
      :default-value="valueToOption(props.context.value)"
      :model-value="valueToOption(props.context.value)"
      :filter-function="filterFunction"
      :display-value="toOptionName"
      :open="menuOpened"
      :disabled="props.context.disabled || props.context.loading"
      :tabindex="props.context.multiple ? 0 : -1"
      @update:open="menuOpened = $event"
      @update:model-value="handleInput"
      @keydown.enter.prevent.stop="handleOpen"
      @keydown.down.prevent.stop="handleOpen"
    >
      <ComboboxAnchor class="w-full rounded-input bg-white px-[1.25rem] outline-none">
        <ComboboxTrigger
          as="div"
          class="flex w-full cursor-pointer flex-row items-center justify-between"
        >
          <ComboboxInput
            v-if="!props.context.multiple"
            ref="input"
            class="w-full border-none py-[0.75rem] placeholder-gray-50 focus:outline-none focus:ring-0 dark:bg-gray-60"
            :placeholder="props.context.placeholder"
            :disabled="props.context.disabled || props.context.loading"
          />
          <div
            v-else
            class="w-full border-none py-[0.75rem] placeholder-gray-50 focus:outline-none focus:ring-0 dark:bg-gray-60"
          >
            {{ displayName }}
          </div>
          <div class="h-5 w-5 transform transition-all" :class="{ 'rotate-180': menuOpened }">
            <ExpandMoreOutlined aria-hidden="true" />
          </div>
        </ComboboxTrigger>
      </ComboboxAnchor>

      <ComboboxContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade absolute z-10 mt-1 w-full overflow-auto rounded-menu bg-white p-[0.625rem] font-light shadow-menu ring-0 transition-opacity duration-150 ease-in will-change-[opacity,transform] focus:outline-none dark:bg-gray-60"
        :class="{
          'mt-0 -translate-y-[calc(100%+53px)]': props.context.openDirection === 'UPWARDS'
        }"
        :style="{
          width: mdAndLarger
            ? props.context.dropDownWidthOverride
              ? dropdownWidth + 'px'
              : null
            : null,
          marginTop: props.context.dropDownMarginTopOverride || null,
          translate: mdAndLarger ? dropDownTranslateX + 'px' : '0px'
        }"
      >
        <ComboboxInput
          v-if="props.context.multiple"
          ref="input"
          autofocus
          class="w-full border-none px-[0.62rem] py-[0.75rem] placeholder-gray-50 focus:outline-none focus:ring-0 dark:bg-gray-60"
          :placeholder="$t('common.form.select.search')"
        />

        <div
          v-if="props.context.multiple"
          class="flex select-none justify-between px-[0.6rem] py-[0.625rem] text-t5 font-light"
        >
          <div>
            {{ selectedCount }}
            {{ props.context.selectedText || $t('common.form.select.selected', selectedCount) }}
          </div>
          <button
            type="button"
            class="!text-t6 underline"
            aria-label="Reset"
            tabindex="-1"
            @click="reset"
          >
            {{ props.context.reseText || $t('common.form.select.reset') }}
          </button>
        </div>
        <ComboboxViewport :class="{ 'max-h-[25vh]': !mdAndLarger, 'max-h-96': mdAndLarger }">
          <ComboboxEmpty class="px-[0.7rem] py-[0.625rem] text-t5 font-light">
            {{ props.context.noOptionsText || $t('common.form.select.noMatch') }}
          </ComboboxEmpty>

          <ComboboxItem
            v-for="option in props.context.itemOptions"
            :key="toOptionValue(option)"
            :class="{
              'group relative flex h-[2.5rem] cursor-pointer select-none items-center rounded-md leading-none data-[disabled]:pointer-events-none data-[highlighted]:bg-primary data-[disabled]:text-gray-50 data-[highlighted]:text-white data-[highlighted]:outline-none': true,
              'pl-[20px]': !props.context.multiple,
              'pl-[30px]': props.context.multiple
            }"
            :value="option"
            @select.prevent="handleOptionClicked"
          >
            <span
              v-if="props.context.multiple"
              class="absolute left-[4px] inline-flex w-5 items-center justify-center"
            >
              <CheckBoxCheckedOutlined v-if="hasOptionSelected(toOptionValue(option))" />
              <CheckBoxBlankOutlined v-else />
            </span>
            <span>
              {{ toOptionName(option) }}
            </span>
          </ComboboxItem>
        </ComboboxViewport>
      </ComboboxContent>
    </ComboboxRoot>
  </div>
</template>

<style lang="scss">
.dark {
  .select-input {
    @apply text-t5 text-gray-30;
  }
}
.select-input {
  @apply text-t5 text-gray-60;
  button {
    @apply text-t5;
  }
}
[data-invalid] .select-input {
  @apply ring-1 ring-red-40;
}
</style>
