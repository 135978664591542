import { twMerge } from 'tailwind-merge'
import { clsx, type ClassValue } from 'clsx'

export function tailwindMerge(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export default {
  class: {
    type: [String, Object],
    default: ''
  }
}
