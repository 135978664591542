<script setup lang="ts">
const toLocalePath = useLocalePath()

const props = defineProps({
  error: {
    type: Object as PropType<Error & { statusCode?: number; statusMessage?: string }>,
    required: true
  }
})

console.log('Error page error:', props.error)

const action = ref<'home' | 'refresh'>('home')

const refreshCounter = ref(60)
let interval: ReturnType<typeof setInterval>

if (
  props.error.statusMessage?.includes('imported module') ||
  props.error.message.includes('imported module') ||
  props.error.statusMessage?.includes('chunk') ||
  props.error.message.includes('chunk')
) {
  action.value = 'refresh'
} else {
  action.value = 'home'
}

if (action.value === 'refresh') {
  interval = setInterval(() => {
    refreshCounter.value--
    console.log('refreshCounter', refreshCounter.value)
    if (refreshCounter.value === 0) {
      clearInterval(interval)
      refresh()
    }
  }, 1000)
}

onUnmounted(() => {
  if (interval) {
    clearInterval(interval)
  }
})

const message = computed(() => {
  // Deployment client version outdated error
  if (action.value === 'refresh') {
    return `We are updating, refreshing in ${refreshCounter.value} seconds...`
  }
  // TODO Auth token expired error
  const statusCode = props.error.statusCode || 500
  // Server error
  if (statusCode >= 500) {
    return 'This page is temporarily unavailable. Please try again later.'
  }
  // Client 404 error
  if (statusCode >= 400 && statusCode < 500) {
    return 'This requested page is no longer available.'
  }
  return 'Something went wrong.'
})

function refresh() {
  reloadNuxtApp()
}
</script>
<template>
  <NuxtLayout>
    <div class="container flex items-center lg:min-h-[calc(100vh-68px)]">
      <div class="flex flex-col gap-[1.88rem] text-h3m lg:text-h3">
        <div>
          {{ message }}
        </div>
        <div>
          <ButtonBlackButton
            v-if="action === 'home'"
            mode="navigation"
            size="medium"
            text="Back to home page"
            variant="text"
            :to="toLocalePath('/')"
          >
          </ButtonBlackButton>

          <ButtonBlackButton v-else size="medium" text="Refresh" variant="text" @click="refresh" />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
